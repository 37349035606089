@charset "UTF-8";
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.section--serial-tag {
  padding: 6rem 0 clamp(2rem, -3.00752vw + 2.706766875rem, 0rem);
}
.section--serial-tag-grey {
  background-color: #DCDCDC;
}
.section--serial-tag-grey .qpac.serial-tag {
  border-color: #1C1D21;
  color: #1C1D21;
}
.section--serial-tag-grey .qpac.serial-tag .serial-tag__title {
  border-left-color: #1C1D21;
}

.qpac.serial-tag {
  display: inline-flex;
  border: 0.0625rem solid #DCDCDC;
}
.qpac.serial-tag .serial-tag__no {
  padding: 0.4375rem 0.5625rem 0.4375rem 0.4375rem;
  font-size: 0.75rem;
  line-height: 120%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__no {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title {
  display: flex;
  flex-direction: row;
  padding: 0.4375rem 1.4375rem 0.4375rem 0.4375rem;
  border-left: 0.0625rem solid #DCDCDC;
  gap: 0.5rem;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__title {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title h2 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.serial-tag .serial-tag__title span {
  font-size: 0.75rem;
  line-height: 120%;
}
.qpac.serial-tag.black-variant {
  border: 0.0625rem solid #1C1D21;
  color: #1C1D21;
}
.qpac.serial-tag.black-variant .serial-tag__title {
  border-left: 0.0625rem solid #1C1D21;
}

.qpac.story-article-card {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.9375rem;
}
.qpac.story-article-card .story-article-card__wrapper {
  margin-bottom: auto;
}
.qpac.story-article-card .story-article-card__wrapper figure {
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  padding: 0.875rem;
  background-image: linear-gradient(0deg, #4C515C, #4C515C 10.5%, transparent 10.5%, transparent 89.5%, #4C515C 75%), linear-gradient(90deg, #4C515C, #4C515C 10.5%, transparent 10.5%, transparent 89.5%, #4C515C 75%), linear-gradient(180deg, #4C515C, #4C515C 10.5%, transparent 10.5%, transparent 89.5%, #4C515C 75%), linear-gradient(270deg, #4C515C, #4C515C 10.5%, transparent 10.5%, transparent 89.5%, #4C515C 75%);
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 0.0625rem 100%, 100% 0.0625rem, 0.0625rem 100%, 100% 0.0625rem;
}
.qpac.story-article-card .story-article-card__wrapper figure svg, .qpac.story-article-card .story-article-card__wrapper figure img {
  width: 1.25rem;
  height: 1.25rem;
}
.qpac.story-article-card .story-article-card__wrapper article {
  margin-top: 1.0625rem;
}
.qpac.story-article-card .story-article-card__wrapper article h2 {
  margin-bottom: clamp(0.25rem, 0.04699vw + 0.238956875rem, 0.28125rem);
  color: #00FFAE;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}
.qpac.story-article-card .story-article-card__wrapper article p {
  padding-right: 1.4375rem;
}
@media screen and (min-width:480px) {
  .qpac.story-article-card .story-article-card__wrapper article p {
    padding-right: 0;
  }
}
.qpac.story-article-card .story-article-card__wrapper article address {
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.031875rem;
  line-height: 135%;
}
.qpac.story-article-card .story-article-card__wrapper article .story-article-card__phone {
  font-weight: 300;
  letter-spacing: -0.031875rem;
  line-height: 135%;
  transition: all 0.3s ease-in-out;
}
.qpac.story-article-card .story-article-card__wrapper article .story-article-card__phone:hover {
  color: #fff;
}
.qpac.story-article-card .story-article-card__wrapper article .story-article-card__link {
  font-weight: 300;
  letter-spacing: -0.031875rem;
  line-height: 135%;
  text-decoration: underline;
  text-decoration-color: #DCDCDC;
  text-underline-offset: 0.125rem;
  transition: all 0.3s ease-in-out;
}
.qpac.story-article-card .story-article-card__wrapper article .story-article-card__link::after {
  content: " →";
}
.qpac.story-article-card .story-article-card__wrapper article .story-article-card__link:hover {
  color: #fff;
  text-decoration-color: transparent;
}
.qpac.story-article-card .l-btn {
  max-width: 13.75rem;
}
.qpac.story-article-card:hover .l-btn {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}
@media screen and (min-width:991px) {
  .qpac.story-article-card:hover .l-btn {
    background-color: unset;
  }
}
.qpac.story-article-card:hover .l-btn::before {
  left: 0;
}
.qpac.story-article-card:hover .l-btn::after {
  color: #000;
}
@media screen and (min-width:1199px) {
  .qpac.story-article-card--grey .story-article-card__wrapper article {
    padding-right: 3.75rem;
  }
}
.qpac.story-article-card--grey .story-article-card__wrapper article h2 {
  color: #16171A;
}
.qpac.story-article-card--grey .story-article-card__wrapper article p {
  color: #242830;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.0175rem;
  text-transform: uppercase;
}

.section--story-articles {
  position: relative;
}
.section--story-articles::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 2rem);
  height: 1px;
  background-color: #8F9091;
  content: "";
  opacity: 1;
  transform: translateX(-50%);
}
@media screen and (min-width:991px) {
  .section--story-articles::after {
    width: calc(100% - 2.5rem);
  }
}
.section--story-articles:has(.story-articles--grid-grey), .section--story-articles:has(.story-articles--grey) {
  background-color: #DCDCDC;
}
.section--story-articles:has(.story-articles--grid-black) {
  background-color: #16171A;
}
.section--story-articles:has(.story-articles--grid.story-articles--product) {
  padding-top: clamp(0rem, 3.7594vw - 0.88345875rem, 2.5rem);
}
.section--story-articles:has(.story-articles.story-articles--adaptive)::after, .section--story-articles:has(.story-articles.story-articles--solutions)::after, .section--story-articles:has(.story-articles.story-articles--no-ruler)::after {
  display: none;
}

.qpac.story-articles {
  padding-top: clamp(3.9375rem, 3.19549vw + 3.18656rem, 6.0625rem);
}
.qpac.story-articles header {
  margin: clamp(1.5rem, 3.1015vw + 0.771146875rem, 3.5625rem) 0 2.5rem clamp(0rem, 5.16917vw - 1.214755625rem, 3.4375rem);
}
@media screen and (min-width:991px) {
  .qpac.story-articles header {
    margin-bottom: 0;
  }
}
.qpac.story-articles header h2 {
  max-width: 19.375rem;
  margin-bottom: 0;
  color: #DCDCDC;
  font-size: clamp(2rem, 0.93985vw + 1.779135625rem, 2.625rem);
  font-weight: 400;
  letter-spacing: -0.02625rem;
  line-height: 100%;
  text-transform: uppercase;
}
@media screen and (min-width:480px) {
  .qpac.story-articles header h2 {
    max-width: 70%;
  }
}
@media screen and (min-width:991px) {
  .qpac.story-articles header h2 {
    max-width: 47.1875rem;
  }
}
.qpac.story-articles header h2 br {
  display: none;
}
@media screen and (min-width:991px) {
  .qpac.story-articles header h2 br {
    display: block;
  }
}
.qpac.story-articles header p {
  max-width: 24.6875rem;
  margin-top: 0.625rem;
}
.qpac.story-articles .story-aricles__list {
  display: grid;
  padding: clamp(2.5rem, 5.16917vw + 1.285244375rem, 5.9375rem) 0 clamp(2.5rem, 5.16917vw + 1.285244375rem, 5.9375rem) clamp(0rem, 5.54511vw - 1.30310125rem, 3.6875rem);
  gap: 3.5rem clamp(3.5rem, 1.8797vw + 3.058270625rem, 4.75rem);
  grid-template-columns: auto;
  grid-template-rows: auto;
}
@media screen and (min-width:640px) {
  .qpac.story-articles .story-aricles__list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4.75rem;
  }
}
@media screen and (min-width:991px) {
  .qpac.story-articles .story-aricles__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.qpac.story-articles--grid.story-articles--product {
  padding-top: clamp(2.5rem, 1.50376vw + 2.14661625rem, 3.5rem);
}
@media screen and (min-width:640px) {
  .qpac.story-articles--grid .story-aricles__list {
    gap: 5.90625rem 4.5625rem;
  }
}
@media screen and (min-width:640px) {
  .qpac.story-articles--grid-grey .story-aricles__list, .qpac.story-articles--grid-black .story-aricles__list {
    padding: 3.5rem 3.5625rem 6rem clamp(0rem, 44.375vw - 17.75rem, 22.1875rem);
    gap: 4.5rem 4.75rem;
    grid-template-columns: repeat(2, auto);
  }
}
.qpac.story-articles--grid-black .story-article-card__wrapper article h2 {
  color: #00FFAE;
}
.qpac.story-articles--grid-black .story-article-card__wrapper article p {
  color: #DCDCDC;
}
.qpac.story-articles--grid-grey header h2, .qpac.story-articles--grid-grey header p, .qpac.story-articles--grey header h2, .qpac.story-articles--grey header p {
  color: #1C1D21;
}
.qpac.story-articles--product {
  padding-top: clamp(4rem, 3.1015vw + 3.271146875rem, 6.0625rem);
}
@media screen and (min-width:640px) {
  .qpac.story-articles--product .story-aricles__list {
    padding-bottom: 7.875rem;
  }
}
.qpac.story-articles--adaptive header h2 {
  max-width: 47.1875rem;
}
.qpac.story-articles--adaptive .story-aricles__list {
  padding-bottom: clamp(2.5rem, 7.98872vw + 0.622650625rem, 7.8125rem);
}
.qpac.story-articles--contact {
  padding: 0;
}
.qpac.story-articles--contact .story-aricles__list {
  padding-top: clamp(4rem, 2.91353vw + 3.315319375rem, 5.9375rem);
  gap: 1.0625rem;
}
@media screen and (min-width:640px) {
  .qpac.story-articles--contact .story-aricles__list {
    padding-left: 0;
  }
}
@media screen and (min-width:991px) {
  .qpac.story-articles--contact .story-aricles__list {
    grid-template-columns: repeat(3, 32.5%);
  }
}
.qpac.story-articles--contact .story-aricles__list .story-article-card {
  padding-bottom: 3rem;
  position: relative;
}
.qpac.story-articles--contact .story-aricles__list .story-article-card::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 0rem);
  height: 1px;
  background-color: #4C515C;
  content: "";
  opacity: 1;
  transform: translateX(-50%);
}
@media screen and (min-width:991px) {
  .qpac.story-articles--contact .story-aricles__list .story-article-card::after {
    width: calc(100% - 0rem);
  }
}
.qpac.story-articles--no-ruler {
  padding-bottom: clamp(0.75rem, 1.69173vw + 0.35244375rem, 1.875rem);
}